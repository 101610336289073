import React from "react"
import "./text-section.scss"

const TextSection = ({ children }) => (
  <section className="text-section">
    <div className="container center">
      <div className="text-content">{children}</div>
    </div>
  </section>
)

export default TextSection
