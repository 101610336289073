import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import TextSection from "../components/text-section/text-section"
import { graphql } from "gatsby"
import Hero from "../components/hero/hero"
import TeamSection from "../components/team-section/team-section"
import "../style/utility/special-headings.scss"
import Timeline from "../components/timline/timeline"

const IndexPage = props => {
  const data = props.data
  const executiveCommittee = data.executives.edges
  const boardOfDirectors = data.directors.edges
  const playerRelations = data.playerRelations.edges
  const executiveDirector = data.executiveDirector.edges
  const events = data.events.edges

  return (
    <Layout>
      <SEO title="USLPA - Board of Directors" />
      <Hero title="About Us" />
      <div className="white-content">
        <TextSection>
          <p>
            The USLPA is an organization that is build on the mantra “for
            players, by players.” Not only was the organization created by USL
            players, it is run by and for USL players. The players on each team
            vote to elect a Team Representatives, and those Player
            Representatives elect a seven-player Executive Board. The Executive
            Board governs the PA and makes all major decisions. The Executive
            Board also hires the PA’s Executive Director, who runs the
            day-to-day operations of the PA, and its General Counsel, who
            provides legal advice to the PA.
          </p>
        </TextSection>
      </div>
      <div className="chevron-detail">
        <div className="about--wrapper container">
          <div className="about-teams--content">
            <h2 className="special-heading--right spaced-out spaced-out--long">
              Board of Directors
            </h2>
            <TeamSection
              title={"Executive Committee - 2022"}
              members={executiveCommittee}
            >
              <p>
                The Executive Committee oversees and directs the USLPA Board of
                Directors and provides oversight of USLPA operations including
                communications, strategy, business development, internal
                communication, finances, personnel management, collective
                bargaining committee, and media.
              </p>
            </TeamSection>
            <TeamSection
              title={"Board of Directors - 2022"}
              members={boardOfDirectors}
            >
              <p>
                The Board of Directors organizes the structure, vision, and
                decision-making of the Players Association. They also oversee
                Collective Bargaining Agreement construction, negotiation and
                ratification, communicate with Team Representatives as delegated
                by Executive Directors, participate in necessary USLPA
                committees, and provide insight into internal USLPA discussions.
              </p>
            </TeamSection>
          </div>
        </div>
      </div>
      <div className="chevron-detail">
        <div className="about--wrapper about--wrapper__teams container">
          <div className="about-teams--content">
            <h2 className="special-heading--right spaced-out">Our Team</h2>
            <TeamSection
              title={"Executive Director - 2022"}
              members={executiveDirector}
              card
            >
              <p>
                Oversees and directs all USLPA operations, communications,
                strategy, business development, player relations, internal
                communication and management, finances, personnel management,
                board of directions, collective bargaining committee, media, and
                social media.
              </p>
            </TeamSection>
            <TeamSection
              title={"Player Relations"}
              members={playerRelations}
              card
            >
              <p>
                The Player Relations Department serves as the primary bridge
                between the USLPA Executive Committee and the overall USL player
                pool. They advocate and support players with a wide variety of
                issues and communicate with the USL League Office on behalf of
                players.
              </p>
            </TeamSection>
          </div>
        </div>
      </div>
      <div className="about--wrapper container">
        <h2 className="special-heading--left spaced-out">USLPA Timeline</h2>
        <Timeline events={events} />
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    directors: allBoardOfDirectorsJson {
      edges {
        node {
          name
          title
          headshot {
            alt
            src {
              childImageSharp {
                fixed(height: 250, width: 250, cropFocus: NORTH) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
          bio
        }
      }
    }
    executives: allExecutiveCommitteeJson {
      edges {
        node {
          name
          title
          headshot {
            alt
            src {
              childImageSharp {
                fixed(height: 250, width: 250) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
          bio
        }
      }
    }
    playerRelations: allPlayerRelationsJson {
      edges {
        node {
          name
          email
          headshot {
            alt
            src {
              childImageSharp {
                fixed(height: 250, width: 250) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    executiveDirector: allExecutiveDirectorJson {
      edges {
        node {
          name
          email
          headshot {
            alt
            src {
              childImageSharp {
                fixed(height: 250, width: 250) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    events: allTimelineJson {
      edges {
        node {
          date
          event
        }
      }
    }
  }
`
