import React from "react"
import "./team-section.scss"
import HeadShot from "../headshot/headshot"
import CallingCard from "../calling-card/calling-card"

const TeamSection = ({ title, children, members, card }) => {
  return (
    <div className="container team-section--wrapper">
      <div className="team-section--header">
        <h3>{title}</h3>
      </div>
      {children}
      <div className={card ? "team-section--members--card" : "team-section--members"}>
        {members.map((member, index) =>
          card ? (
            <CallingCard data={member.node} key={index} />
          ) : (
            <HeadShot data={member} key={index} />
          )
        )}
      </div>
    </div>
  )
}

export default TeamSection
