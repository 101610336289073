import React from "react"
import "./timeline.scss"

const Timeline = ({ events }) => (
  <section className="timeline">
    {events.map((event, index) => (
      <div className="timeline-event">
        <div className="timeline-event--date">
          <p>{event.node.date}</p>
        </div>
        <div className="timeline-event--event">
          <p>{event.node.event}</p>
        </div>
      </div>
    ))}
  </section>
)

export default Timeline
