import React from "react"
import Img from "gatsby-image"
import "./circle-img.scss"

const CircleImg = ({headshot}) => {
  return (
    <Img
      fixed={headshot.src.childImageSharp.fixed}
      className="circle-img"
      alt={headshot.alt}
    />
  )
}

export default CircleImg
