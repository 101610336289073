import React from "react"
import CircleImg from '../circle-img/circle-img'
import "./headshot.scss"

const HeadShot = props => {
  const data = props.data.node
  return (
    <div className="headshot-wrapper">
      <CircleImg headshot={data.headshot} />
      <div className="headshot-content">
        <h3>{data.name}</h3>
        {data.title && <h4>{data.title}</h4>}
      </div>
    </div>
  )
}

export default HeadShot
