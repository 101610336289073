import React from "react"
import CircleImg from "../circle-img/circle-img"
import "./calling-card.scss"

const CallingCard = ({ data }) => {
  return (
    <div className="calling-card--wrapper">
      <CircleImg headshot={data.headshot} />
      <div className="calling-card--content">
        <h4>{data.name}</h4>
        <a href={"mailto:" + data.email}>{data.email}</a>
      </div>
    </div>
  )
}

export default CallingCard
